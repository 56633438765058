






















































/**
 * @name 解决方案
 * */
import {
  defineComponent,
  ref,
  onBeforeMount,
  toRefs,
} from "@vue/composition-api";
import bgImg from "@/components/bgImg/index.vue";
import Menu from "@/components/menu/index.vue";
import InfoWarp from "@/components/infowarp/index.vue";
import offcanvas from "@/components/offcanvas/index.vue";
import { useList, getListToTypeFn, useDom } from "@/use";
import { getList } from "@/api/index";

export default defineComponent({
  components: {
    bgImg,
    Menu,
    InfoWarp,
    offcanvas,
  },
  setup() {
    const src = ref("solution/solution.jpeg");
    const title = "解决方案";

    let activeId = ref(0);
    const formData = ref({
      ids: "",
    });
    const { skipPage, offcanvas, showOffcanvasMenu, changeURLStatic } =
      useDom();
    const {
      state,
      getTableListData,
      // nodeClick: nCfn,
      query,
      Mdata,
      nodeClick: nCfn,
      meunActive,
      getListMenuFn,
      resetState,
    } = useList("solution", () => {
      return new Promise((reslove) => {
        // 获取表格数据、导入接口
        getList({
          ids: formData.value.ids,
          pageNum: state.listQuery.pageNum,
          pageSize: state.listQuery.pageSize,
        }).then((res) => {
          reslove(res.data);
        });
      });
    });
    onBeforeMount(async () => {
      let { value, id } = (await getListMenuFn()) as any;
      formData.value.ids = value || id;
      getTableListData();
      if (query.menuactive) {
        activeId.value = Number(query.menuactive);
      }
    });
    // 点击目录回掉
    const nodeClick = (val: any) => {
      console.log(val);
      nCfn(val);
      activeId.value = val.id;
      formData.value.ids = val.value || val.id;
      changeURLStatic("menuactive", val.id);
      resetState(); // 重置页码
      getTableListData();
    };
    const handleDetails = (row: any) => {
      skipPage("solution-info", {
        type: "details",
        id: row.id,
        menuactive: activeId.value,
        // value: row.value,
      });
    };
    return {
      src,
      title,
      // arr,
      // activeId,
      ...toRefs(state),
      nodeClick,
      handleDetails,
      offcanvas,
      showOffcanvasMenu,
      query,
      Mdata,
      ...toRefs(meunActive),
    };
  },
});
